import { createStore } from 'vuex'

export default createStore({
  state: {
    municipio:[]
  },
  mutations: {
    setMunicipio(state,payload){
      state.municipio = payload
    }
  },
  actions: {
    async getData({commit}){
      try {
        const response = await fetch('../DB/Municipios.json');
        const result = await response.json();
        commit("setMunicipio",result);
      } catch (error) {
        console.log(error);
      }
    }
  },
  modules: {
  }
})
