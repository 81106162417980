<template>
  <div class="contenedor-principal">
    <h1>Plataforma de Análisis de la Paridad en Morelos</h1>
  </div>
  <router-view/>
</template>

<style>

  body{
    width:100%;
    height:100vh;
    box-sizing:content-box;
    background-color:#f1f1f1;
    margin:0;
    padding:0;
    font-family: 'Montserrat', sans-serif;
  }

  .contenedor-principal{
    width:100%;
    height:80px;
    display:flex; align-items:center; justify-content:center;
    background-color: black;
    color: #fff;
    margin:0;
  }
  .contenedor-principal h1{
    margin:0;
    padding:0;
    font-size:16px;
  }
</style>
